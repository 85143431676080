var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <button type=\"button\" class=\"btn "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_current") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":93}}})) != null ? stack1 : "")
    + "\" data-action=\"position\" data-position=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"position") || (depth0 != null ? lookupProperty(depth0,"position") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"position","hash":{},"data":data,"loc":{"start":{"line":13,"column":133},"end":{"line":13,"column":145}}}) : helper)))
    + "\">\n            "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":23}}}) : helper)))
    + "\n        </button>\n    </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "btn-primary";
},"8":function(container,depth0,helpers,partials,data) {
    return "btn-alt";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li>\n    <button type=\"button\" class=\"btn btn-alt\" data-action=\"first\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFirst") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":66},"end":{"line":2,"column":108}}})) != null ? stack1 : "")
    + ">\n        <i class=\"fa fa-angle-double-left\"></i>\n    </button>\n</li>\n<li>\n    <button type=\"button\" class=\"btn btn-alt\" data-action=\"previous\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasPrevious") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(1, data, 0),"data":data,"loc":{"start":{"line":7,"column":69},"end":{"line":7,"column":123}}})) != null ? stack1 : "")
    + ">\n        <i class=\"fa fa-angle-left\"></i>\n    </button>\n</li>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"pages") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":17,"column":9}}})) != null ? stack1 : "")
    + "<li>\n    <button type=\"button\" class=\"btn btn-alt\" data-action=\"next\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasNext") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(1, data, 0),"data":data,"loc":{"start":{"line":19,"column":65},"end":{"line":19,"column":115}}})) != null ? stack1 : "")
    + ">\n        <i class=\"fa fa-angle-right\"></i>\n    </button>\n</li>";
},"useData":true});